import { useSpring, animated } from "react-spring";
import "./index.css";
import Flower from "./backgrounds/Flower.jpg";
import Mushroom from "./backgrounds/Mushroom.jpg";
import Pinecone from "./backgrounds/Pinecone.jpg";

function random(array) {
	return array[Math.floor(Math.random() * array.length)];
}

const background = random([Flower, Mushroom, Pinecone]);
const backgroundImage = `url(${background.replace(/\.jpg$/i, "\\.jpg")})`;
console.log(background, backgroundImage);

export default function Background() {
	const spring = useSpring({
		config: { friction: 0, mass: 200 },
		from: {
			transform: "translateY(-20px)",
			backgroundImage,
		},
		to: { transform: "translateY(-10px)", backgroundImage },
	});

	return <animated.div id="background" style={spring}></animated.div>;
}
