import { Link } from "react-router-dom";

export default function Home() {
	return (
		<>
			<div className="card">
				<Link to="/programming">Programs</Link>
			</div>
			<div className="card">
				<Link to="/stunts">Stunts</Link>
			</div>
			<div className="card">
				<Link to="/digital-media">Digital Media</Link>
			</div>
			<div className="card">
				<Link to="/contact">Contact</Link>
			</div>
		</>
	);
}
