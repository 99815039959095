import "./HomeButton.css";
import { Link } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCampground } from "@fortawesome/free-solid-svg-icons";

import { useLocation } from "react-router-dom";
import { useSpring, animated } from "react-spring";

export default function HomeButton() {
	const location = useLocation();

	const fade = useSpring({
		to: {
			opacity: location.pathname !== "/" ? 1 : 0,
			pointerEvents: location.pathname !== "/" ? "all" : "none",
		},
		from: { opacity: 0 },
	});

	return (
		<animated.div style={fade}>
			<Link id="home-button" to="/">
				<FontAwesomeIcon icon={faCampground} size="2x" />
			</Link>
		</animated.div>
	);
}
