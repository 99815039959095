import dtasPoster from "./DTAS.jpg";
import theLandPoster from "./TheLand.jpg";
import lastSummerPoster from "./TheLastSummer.jpg";
import pupsUnitedPoster from "./PupsUnited.jpg";
import projectUnclePoster from "./ProjectUncle.png";

export default function Stunts() {
	return (
		<>
			<a href="https://youtu.be/wBa3OdiTs80" className="card poster">
				<h2>Actor/Stunt</h2>
				<img
					className="image"
					src={projectUnclePoster}
					alt="Project Uncle Poster"
				/>
			</a>
			<a href="https://www.imdb.com/title/tt8046498" className="card poster">
				<h2>Stunt Double for Jack Dylan Grazer</h2>
				<img
					className="image"
					src={dtasPoster}
					alt="Don't Tell a Soul Poster"
				/>
			</a>
			<a href="https://www.imdb.com/title/tt5164412" className="card poster">
				<h2>Stunt</h2>
				<img className="image" src={theLandPoster} alt="The Land Poster" />
			</a>
			<a href="https://www.imdb.com/title/tt7957694" className="card poster">
				<h2>Stunt</h2>
				<img
					className="image"
					src={lastSummerPoster}
					alt="The Last Summer Poster"
				/>
			</a>
			<a href="https://www.imdb.com/title/tt3725034" className="card poster">
				<h2>Actor/Featured Extra</h2>
				<img
					className="image"
					src={pupsUnitedPoster}
					alt="Pups United Poster"
				/>
			</a>
		</>
	);
}
