import { useEffect, useState } from "react";
import "./Copyright.css";

export default function Copyright() {
	const [year, setYear] = useState(null);

	useEffect(() => {
		fetch("https://worldtimeapi.org/api/ip", {
			method: "get",
			headers: {
				"Access-Control-Request-Headers": "*",
				"Access-Control-Request-Method": "*",
			},
		})
			.then((response) => response.json())
			.then((data) => setYear(new Date(data.datetime).getFullYear()));
	});

	return (
		<div id="copyright">
			© Tyler Cahill 2021{year ? (year === 2021 ? "" : `-${year}`) : "-Present"}
		</div>
	);
}
