import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faGithub } from "@fortawesome/free-brands-svg-icons";

export default function Programming() {
	return (
		<>
			<div className="card">
				<h2>Kernel</h2>
				<p>
					A super small and fast Electron client mod with the most capability.
				</p>
				<a href="https://github.com/kernel-mod">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>QAR</h2>
				<p>
					An archive inspired by ASAR meant for compression while keeping quick random access.
				</p>
				<a href="https://github.com/Kyza/qar">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>			<div className="card">
				<h2>Compendium DevTools</h2>
				<p>
					A framework-agnostic devtools extension made for SolidHack.
				</p>
				<a href="https://github.com/CompendiumDevTools">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>Nests</h2>
				<p>
					Fast and easy state storage with a lot of control.
				</p>
				<a href="https://github.com/Kyza/nests">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>Worst JSON Stringify</h2>
				<p>
					Extremely fast JSON stringifying without iteration.
				</p>
				<a href="https://github.com/Kyza/worst-json-stringify">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>Powercord Plugins (Unmaintained)</h2>
				<p>My old Powercord plugins.</p>
				<a href="https://github.com/kyza-powercord">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>BetterDiscord Plugins (Unmaintained)</h2>
				<p>My old BetterDiscord plugins.</p>
				<a href="https://github.com/Kyza/Khub">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>Ittai</h2>
				<p>
					Ittai is a plugin builder for Discord client mods. Which ones? Yes.
				</p>
				<a href="https://github.com/Kyza/ittai">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>BDBuilder</h2>
				<p>
					Simpler version of Ittai for only BD.
				</p>
				<a href="https://github.com/Kyza/bdbuilder">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>gsetw</h2>
				<p>
					The fastest object node waiter possible.
				</p>
				<a href="https://github.com/Kyza/gsetw">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
			<div className="card">
				<h2>SoulFinder</h2>
				<p>
					An experimental website for showing instructions on how to find the
					fairy souls in Hypixel Skyblock. This was my first ever React website.
				</p>
				<a href="https://github.com/Kyza/SoulFinder">
					<FontAwesomeIcon icon={faGithub} size="2x" />
				</a>
			</div>
		</>
	);
}
