import "./App.css";
import "./components/Card.css";

import { React, useRef } from "react";
import { useSpring, useChain, useTransition, animated } from "react-spring";
import Background from "./components/Background/index";
import Home from "./pages/Home";
import Programming from "./pages/Programming";
import Stunts from "./pages/Stunts";
import DigitalMedia from "./pages/DigitalMedia";
import Contact from "./pages/Contact";
import HomeButton from "./components/HomeButton";
import Copyright from "./components/Copyright";

import { Switch, Route, useLocation } from "react-router-dom";

function App() {
	const location = useLocation();

	const routesRef = useRef();
	const routesTrans = useTransition(location, (l) => l.pathname, {
		ref: routesRef,
		unique: true,
		reset: true,
		from: {
			opacity: 0,
		},
		enter: [{ opacity: 1 }],
		leave: [
			{
				opacity: 0,
			},
		],
	});

	const fadeRef = useRef();
	const fade = useSpring({
		ref: fadeRef,
		to: { opacity: 1 },
		from: { opacity: 0 },
	});

	useChain([fadeRef, routesRef]);

	return (
		<animated.div className="App" style={fade}>
			<Background />
			{routesTrans.map(({ item, props, key }) => (
				<animated.div id="page" key={key} style={props}>
					<Switch location={item}>
						<Route path="/" exact component={Home} />
						<Route path="/programming" component={Programming} />
						<Route path="/stunts" component={Stunts} />
						<Route path="/digital-media" component={DigitalMedia} />
						<Route path="/contact" component={Contact} />
					</Switch>
				</animated.div>
			))}
			<HomeButton />
			<Copyright />
		</animated.div>
	);
}

export default App;
